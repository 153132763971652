<template>
  <div class="field-item">
    <div class="field-item__label">
      {{ itemVal.label }}
    </div>
    <textInput
      :typeInput="'number'"
      :value.sync="itemVal.value"
    >
    </textInput>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';

export default {
  name: 'NumberField',
  components: {
    textInput,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    itemVal: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";
@import "../../sass/fields";
</style>
